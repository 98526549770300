/* eslint-disable-next-line new-cap */
import { createI18n } from 'vue-i18n';
import tw from './tw.json';
import en from './en.json';
import jp from './jp.json';
import th from './th.json';
import ko from './ko.json';

const locale = 'zh-tw';

const messages = {
  'zh-tw': tw,
  en,
  ja: jp,
  th,
  ko,
  // fr,
};

/* eslint-disable-next-line new-cap */
const i18n = new createI18n({
  locale,
  messages,
  fallbackLocale: 'zh-tw',
  legacy: false,
});

export default i18n;
