import i18n from '@/i18n';
import * as types from '../mutations_type';

const openDialog = (context, payload) => new Promise((resolve) => {
  const {
    message, callback, icName, okText, cancelText, classOk, hideCancel,
  } = payload;
  const data = {
    icName: icName || 'ic_manta_alert',
    showDialog: true,
    message,
    okText: okText || i18n.global.t('confirm'),
    cancelText: cancelText || i18n.global.t('cancel'),
    hideCancel,
    determine: () => {
      resolve(callback());
      context.dispatch('offDialog');
    },
    cancel: () => {
      context.dispatch('offDialog');
      resolve();
    },
    classOk: classOk || 'btn--outline--primaryDark',
  };
  context.commit(types.DIALOG, data);
});

const offDialog = (context) => {
  const data = {
    message: '',
    showDialog: false,
    cancelText: i18n.global.t('cancel'),
    okText: i18n.global.t('confirm'),
    icName: 'ic_manta_alert',
    classOk: 'btn--outline--primaryDark',
    determine: () => {},
    cancel: () => {},
  };
  context.commit(types.DIALOG, data);
};

export {
  openDialog,
  offDialog,
};
