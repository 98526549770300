import i18n from '@/i18n';

const minLength = (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length < limit) {
    return i18n.global.t('customRules.minLength', { limit });
  }
  return true;
};

const maxLength = (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length > limit) {
    return i18n.global.t('customRules.maxLength', { limit });
  }
  return true;
};

const equalLength = (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length !== limit) {
    return i18n.global.t('customRules.equalLength', { limit });
  }
  return true;
};

export default {
  minLength,
  maxLength,
  equalLength,
};
